import * as React from 'react'
import styled from 'styled-components'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Container from 'components/shared/container'
import Breadcrumbs from 'components/shared/Breadcrumbs'
import { Heading, Text } from 'components/shared/typography'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Polityka prywatności',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Polityka prywatności',
    },
  ],
}

const Section = styled.section`
  width: 100%;
  padding: 20px 0 50px 0;
  ${({ theme }) => theme.media.lg.min} {
    padding: 0 0 50px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const Header = styled.header`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 40px;

  ${({ theme }) => theme.media.lg.min} {
    padding-top: 230px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding-top: 260px;
  }
`

const PolitykaPrywatnosciPage: React.FC<PageProps> = ({ data }) => {
  const PAGE_SEO = data?.wpPage?.seo

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Header>
        <Heading
          size={42}
          weight={600}
          align="center"
          themecolor="white"
          margin="0"
          transform="uppercase"
          dangerouslySetInnerHTML={{ __html: 'polityka prywatności' }}
        />
      </Header>
      <Container>
        <Breadcrumbs crumbs={breadcrumbs} />
      </Container>
      <main>
        <Container>
          <Section>
            <Text
              size={16}
              weight={500}
              themecolor="black100"
              margin="0"
              dangerouslySetInnerHTML={{ __html: data?.wpPage?.content }}
            />
          </Section>
        </Container>
      </main>
    </Layout>
  )
}

export default PolitykaPrywatnosciPage

export const query = graphql`
  query PolitykaPrywatnosciPage {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      title
      content
    }
  }
`
